import React from "react";
import { Container } from "react-bootstrap";

class NotFound extends React.Component {
  render() {
    return (
      <div>
        <Container>
          <h1>Page Not Found</h1>
          <h3>Please visit another URL.</h3>
        </Container>
      </div>
    );
  }
}

export default NotFound;
