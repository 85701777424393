import React from "react";
import { Image } from "react-bootstrap";
import { menu } from "./items.js";
import "../css/menu-sidebar.css";

class MenuLine extends React.Component {
  render() {
    return (
      <div className="menu-line-container">
        <Image
          src={this.props.imageSrc}
          width="18px"
          height="18px"
          style={{ "margin-right": "8px" }}
        />
        <h3>{this.props.line.toUpperCase()}</h3>
      </div>
    );
  }
}

class MenuSidebar extends React.Component {
  render() {
    var menuWithCelebrated = [...menu];
    menuWithCelebrated.unshift({ title: "Most Celebrated" });

    var activeSection = this.props.activeSection;
    var menuSections = menuWithCelebrated.map(function (section) {
      var idName = section.title.toLowerCase().replace(" ", "-");
      var showGreenLine = activeSection.id === idName;

      return (
        <div className="menu-sidebar-section-container">
          <div
            className="menu-sidebar-green-line"
            style={{
              visibility: showGreenLine ? "visible" : "hidden",
            }}
          ></div>
          <a href={"#" + idName}>
            <h3>{section.title.toUpperCase()}</h3>
          </a>
        </div>
      );
    });

    return (
      <div id="menu-sidebar-container" key={this.props.activeSection}>
        <h2>MENU</h2>
        <Image
          src="/images/home/delivery/right-squiggle.svg"
          id="right-squiggle"
        />
        {menuSections}
        <div id="menu-lines">
          <MenuLine imageSrc="/images/menu/sidebar/star.svg" line="Popular" />
          <MenuLine
            imageSrc="/images/menu/sidebar/cow.svg"
            line="Contains Dairy"
          />
        </div>
      </div>
    );
  }
}

export default MenuSidebar;
