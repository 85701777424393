import React from "react";
import HomeDeliverySection from "./HomeDeliverySection.js";
import HomePlantsSection from "./HomePlantsSection.js";
import HomeFeaturedDrinksSection from "./HomeFeaturedDrinksSection.js";
import HomeFeaturedImageSection from "./HomeFeaturedImageSection";
import "../css/home.css";

class Home extends React.Component {
  render() {
    return (
      <div id="home-page">
        <HomeFeaturedImageSection />
        <HomePlantsSection />
        <HomeFeaturedDrinksSection />
        <HomeDeliverySection />
      </div>
    );
  }
}

export default Home;
