import React from "react";
import { Form } from "react-bootstrap";
import "../css/contact-us.css";

class ContactForm extends React.Component {
  render() {
    return (
      <Form>
        <Form.Group>
          <Form.Label>Full Name</Form.Label>
          <Form.Control type="text" className="form-control-line" />
        </Form.Group>

        <Form.Group>
          <Form.Label>Email Address</Form.Label>
          <Form.Control type="email" className="form-control-line" />
        </Form.Group>

        <Form.Group>
          <Form.Label>Subject</Form.Label>
          <Form.Control type="text" className="form-control-line" />
        </Form.Group>

        <Form.Group>
          <Form.Label>Message</Form.Label>
          <Form.Control
            as="textarea"
            rows={11}
            className="form-control-multiline"
          />
        </Form.Group>

        <h3>SEND MESSAGE</h3>
      </Form>
    );
  }
}

class ContactUs extends React.Component {
  render() {
    return (
      <div id="contact-us-yellow-box">
        <div id="contact-us-elements-container">
          <h1>Need something specific?</h1>
          <h1>Let us know!</h1>
          <div id="contact-us-subtitle-container">
            <p>Feel free to give us a call or send us an email.</p>
            <p>We’ll try to get in touch as soon as we can.</p>
          </div>

          <h2>PHONE</h2>
          <p>Roosevelt</p>
          <p>(206) 503-4296</p>
          <br />
          <p>Capitol Hill</p>
          <p className="contact-us-info">(206) 466-6004</p>

          <h2>EMAIL</h2>
          <p className="contact-us-info">jacewang1994@gmail.com</p>
        </div>
      </div>
    );
  }
}

export default ContactUs;
