import React from "react";
import { Image } from "react-bootstrap";
import "../css/about-plants-container.css";

class AboutPlantsContainer extends React.Component {
  render() {
    return (
      <div id="plants-container">
        <Image
          src="/images/about/green_cup.png"
          width="40%"
          style={{ "padding-right": "2%" }}
        />
        <Image
          src="/images/about/tall_plant.png"
          width="15%"
          style={{ "padding-right": "2%" }}
        />
        <Image src="/images/about/wide_plant.png" width="35%" />
        <Image src="/images/about/short_plant.png" width="20%" />
      </div>
    );
  }
}

export default AboutPlantsContainer;
