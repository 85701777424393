import React from "react";
import "../css/storeinfo-text-section.css";

class StoreInfoTextSection extends React.Component {
  render() {
    return (
      <div id="storeinfo-text-section">
        <h2>{this.props.title}</h2>
        <p>{this.props.line1}</p>
        <p style={{ "margin-bottom": "16px" }}>{this.props.line2}</p>
        <p>{this.props.phone}</p>
        <p>{this.props.line3}</p>
        <p>{this.props.line4}</p>
      </div>
    );
  }
}

export default StoreInfoTextSection;
