import React from "react";
import { Image } from "react-bootstrap";
import AboutPlantsContainer from "./AboutPlantsContainer";
import AboutTextContainer from "./AboutTextContainer";
import "../css/about.css";

class About extends React.Component {
  render() {
    return (
      <div id="about-page">
        <Image src="/images/about/about.svg" id="about-image" />
        <div id="plants-text-container">
          <AboutPlantsContainer />
          <AboutTextContainer />
        </div>
      </div>
    );
  }
}

export default About;
