import React from "react";
import { Row, Col } from "react-bootstrap";
import MenuItem from "./MenuItem.js";
import "../css/menu-section.css";

class MenuSection extends React.Component {
  render() {
    var sectionItems = this.props.items.map(function (item) {
      return <MenuItem item={item} />;
    });

    var sectionItemsWithRows = [];
    for (var i = 0; i < sectionItems.length; i = i + this.props.itemsPerRow) {
      var cols = [];
      for (var j = 0; j < this.props.itemsPerRow; ++j) {
        cols.push(<Col>{sectionItems[i + j]}</Col>);
      }

      sectionItemsWithRows.push(
        <Row style={{ marginBottom: "40px" }}>{cols}</Row>
      );
    }

    var idName = this.props.title.toLowerCase().replace(" ", "-");

    return (
      <div
        className="menu-section-container"
        id={idName}
        ref={this.props.refForChild}
        style={{ marginBottom: this.props.spaceBetweenSections }}
      >
        <h1>{this.props.title}</h1>
        <p className="menu-section-subtitle">{this.props.subtitle}</p>
        {sectionItemsWithRows}
      </div>
    );
  }
}

export default MenuSection;
