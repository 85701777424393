import React from "react";
import { Button, Dropdown, Image, Navbar, Nav } from "react-bootstrap";
import "../css/header.css";

class Header extends React.Component {
  render() {
    return (
      <Navbar expand="lg">
        <a href="/" id="navbar-logo-ref">
          <img src="/images/header/logo_banner.svg" id="navbar-logo-img" />
        </a>
        <Navbar.Toggle />
        <Navbar.Collapse>
          <Nav className="ml-auto">
            <div className="leaf-link-container">
              <Image src="/images/header/leaf.svg" />
              <Nav.Link href="/menu">MENU</Nav.Link>
            </div>
            <div className="leaf-link-container">
              <Image src="/images/header/leaf.svg" />
              <Nav.Link href="/storeinfo">LOCATION & HOURS</Nav.Link>
            </div>
            <div className="leaf-link-container">
              <Image src="/images/header/leaf.svg" />
              <Nav.Link href="/about">ABOUT</Nav.Link>
            </div>
            <div className="leaf-link-container">
              <Image src="/images/header/leaf.svg" />
              <Nav.Link href="/contactus">CONTACT US</Nav.Link>
            </div>
            <Dropdown id="order-now-dropdown">
              <Dropdown.Toggle>ORDER NOW</Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item href="https://food.google.com/chooseprovider?restaurantId=%2Fg%2F11k3plrrry&orderType=2&sei=CSydw8eEe1yEEUQZ68mYYltn&utm_campaign&utm_source=share">
                  Roosevelt
                </Dropdown.Item>
                <Dropdown.Item href="https://www.clover.com/online-ordering/tea-addicts-seattle">
                  Capitol Hill
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            {/* <Button
              variant="primary"
              id="order-now-button"
              href="https://www.clover.com/online-ordering/tea-addicts-seattle"
            >
              ORDER NOW
            </Button> */}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
  }
}

export default Header;
