import React from "react";
import { Image, Container, Row, Col } from "react-bootstrap";
import "../css/home-featured-drinks-section.css";

export class FeaturedDrink extends React.Component {
  render() {
    return (
      <div className="home-featured-drink-container">
        <Image src={this.props.imageSrc} />
        <h3>{this.props.line1.toUpperCase()}</h3>
        <h3>{this.props.line2.toUpperCase()}</h3>
      </div>
    );
  }
}

class HomeDrinksSectionNormal extends React.Component {
  render() {
    return (
      <div id="home-featured-drinks-section-normal">
        <div id="home-featured-drinks-yellow-box">
          <div id="home-featured-drinks-text-container">
            <h1>Our most </h1> <h1>celebrated drinks</h1>
            <a href="/menu">
              <h2>TRY THEM ALL</h2>
            </a>
          </div>
        </div>

        <div id="home-featured-drinks-element-container">
          <FeaturedDrink
            imageSrc="/images/drinks/taro_milkshake.png"
            line1="TARO"
            line2="MILK SHAKE"
          />
          <FeaturedDrink
            imageSrc="/images/drinks/rose_yakult.png"
            line1="ROSE"
            line2="YAKULT"
          />
          <FeaturedDrink
            imageSrc="/images/drinks/mango_blendedyogurt.png"
            line1="MANGO"
            line2="BLENDED YOGURT"
          />
        </div>
      </div>
    );
  }
}

class HomeDrinksSectionMobile extends React.Component {
  render() {
    return (
      <div id="home-featured-drinks-section-mobile">
        <div id="home-featured-drinks-yellow-box-mobile">
          <Container>
            <Row>
              <Col>
                <div id="home-featured-drinks-text-container">
                  <h1>Our most </h1> <h1>celebrated drinks</h1>
                  <a href="/menu">
                    <h2>TRY THEM ALL</h2>
                  </a>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <FeaturedDrink
                  imageSrc="/images/drinks/taro_milkshake.png"
                  line1="TARO"
                  line2="MILK SHAKE"
                />
              </Col>
              <Col>
                <FeaturedDrink
                  imageSrc="/images/drinks/rose_yakult.png"
                  line1="ROSE"
                  line2="YAKULT"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <FeaturedDrink
                  imageSrc="/images/drinks/mango_blendedyogurt.png"
                  line1="MANGO"
                  line2="BLENDED YOGURT"
                />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    );
  }
}

class HomeFeaturedDrinksSection extends React.Component {
  render() {
    return (
      <div>
        <HomeDrinksSectionNormal />
        <HomeDrinksSectionMobile />
      </div>
    );
  }
}
export default HomeFeaturedDrinksSection;
