import React from "react";
import MenuCarousel from "./MenuCarousel.js";
import MenuSection from "./MenuSection.js";
import MenuSidebar from "./MenuSidebar.js";
import MenuSidebarMobile from "./MenuSidebarMobile.js";
import { menu } from "./items.js";
import "../css/menu.css";

class Menu extends React.Component {
  constructor(props) {
    super(props);

    // Add Most Celebrated to menu sections
    var menuWithCelebrated = [...menu];
    menuWithCelebrated.unshift({ title: "Most Celebrated" });
    this.sections = menuWithCelebrated;

    this.state = {
      windowWidth: 0,
      windowHeight: 0,
      activeSection: { id: "most-celebrated", ratio: 0 },
    };

    this.updateDimensions = this.updateDimensions.bind(this);

    this.singleRefs = this.sections.reduce((dict, section) => {
      // Ex: Milk Tea -> milk-tea
      var idName = section.title.toLowerCase().replace(" ", "-");
      dict[idName] = { id: idName, ref: React.createRef(), ratio: 0 };
      return dict;
    }, {});

    const callback = (entries) => {
      entries.forEach(
        (entry) =>
          (this.singleRefs[entry.target.id].ratio = entry.intersectionRatio)
      );

      const activeSection = Object.values(this.singleRefs).reduce(
        (acc, value) => (value.ratio > acc.ratio ? value : acc),
        this.state.activeSection
      );

      if (activeSection.ratio > this.state.activeSection.ratio) {
        this.setState({ activeSection });
      }
    };

    this.observer = new IntersectionObserver(callback, {
      root: null,
      threshold: new Array(101).fill(0).map((v, i) => i * 0.01),
    });
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);

    Object.values(this.singleRefs).forEach((value) =>
      this.observer.observe(value.ref.current)
    );
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  updateDimensions() {
    let windowWidth = typeof window !== "undefined" ? window.innerWidth : 0;
    let windowHeight = typeof window !== "undefined" ? window.innerHeight : 0;

    this.setState({ windowWidth, windowHeight });
  }

  render() {
    const isMobileOrTablet = this.state.windowWidth <= 768;
    const isMobile = this.state.windowWidth <= 512;

    var refs = this.singleRefs;
    var menuSections = this.sections.map(function (section) {
      var idName = section.title.toLowerCase().replace(" ", "-");
      if (idName !== "most-celebrated") {
        return (
          <MenuSection
            title={section.title}
            subtitle={section.subtitle}
            items={section.items}
            itemsPerRow={isMobileOrTablet ? 2 : 3}
            spaceBetweenSections={isMobileOrTablet ? "64px" : "144px"}
            refForChild={refs[idName].ref}
          />
        );
      }
    });

    return (
      <div id="menu-container">
        {isMobileOrTablet ? (
          <MenuSidebarMobile activeSection={this.state.activeSection} />
        ) : (
          <MenuSidebar activeSection={this.state.activeSection} />
        )}
        <div id="menu-carousel-and-menu">
          <MenuCarousel
            refForChild={refs["most-celebrated"].ref}
            numItemsPerPage={isMobile ? 2 : 3}
          />
          {menuSections}
        </div>
      </div>
    );
  }
}

export default Menu;
