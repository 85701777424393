import React from "react";
import "../css/storeinfo-text-container.css";
import StoreInfoTextSection from "./StoreInfoTextSection";

class StoreInfoTextContainer extends React.Component {
  render() {
    return (
      <div id="storeinfo-green-box">
        <div id="storeinfo-text-container">
          <h1>Come visit us!</h1>
          <div id="storeinfo-text-section-container">
            <StoreInfoTextSection
              title="ROOSEVELT"
              line1="1410 NE 65th St"
              line2="Seattle, WA 98115"
              phone="(206) 503-4296"
              line3="Monday to Sunday"
              line4="11:30-9 PM"
            />
            <StoreInfoTextSection
              title="CAPITOL HILL"
              line1="1527 Harvard Avenue"
              line2="Seattle, WA 98122"
              phone="(206) 466-6004"
              line3="Monday to Sunday"
              line4="12-8 PM"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default StoreInfoTextContainer;
