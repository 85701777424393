import React from "react";
import { Image } from "react-bootstrap";
import "../css/home-delivery-section.css";

class HomeDeliverySectionNormal extends React.Component {
  render() {
    return (
      <div id="home-delivery-section-normal">
        <div id="home-delivery-container">
          <div class="home-delivery-horizontal-container home-delivery-title-container">
            <Image src="/images/home/delivery/left-squiggle.svg" />
            <h1 id="home-delivery-text">
              Enjoy our tea in the comforts of your own home
            </h1>
            <Image src="/images/home/delivery/right-squiggle.svg" />
          </div>
          <div class="home-delivery-horizontal-container">
            <a href="https://www.ubereats.com/seattle/food-delivery/tea-addicts/CjdlYL0uSuu19IwMx-rwDg">
              <Image src="/images/home/delivery/uber-eats.svg" />
            </a>
            <a href="https://www.doordash.com/store/tea-addicts-seattle-788910">
              <Image src="/images/home/delivery/door-dash.svg" />
            </a>
          </div>
        </div>
      </div>
    );
  }
}

class HomeDeliverySectionMobile extends React.Component {
  render() {
    return (
      <div id="home-delivery-section-mobile">
        <div id="home-delivery-container">
          <div id="home-delivery-section-mobile-top">
            <Image src="/images/home/delivery/right-squiggle.svg" />
            <h1 id="home-delivery-text">
              Enjoy our tea in the comforts of your own home
            </h1>
          </div>
          <a href="https://www.ubereats.com/seattle/food-delivery/tea-addicts/CjdlYL0uSuu19IwMx-rwDg">
            <Image src="/images/home/delivery/uber-eats.svg" />
          </a>
          <a href="https://www.doordash.com/store/tea-addicts-seattle-788910">
            <Image src="/images/home/delivery/door-dash.svg" />
          </a>
        </div>
      </div>
    );
  }
}

class HomeDeliverySection extends React.Component {
  render() {
    return (
      <div>
        <HomeDeliverySectionNormal />
        <HomeDeliverySectionMobile />
      </div>
    );
  }
}

export default HomeDeliverySection;
