import React from "react";
import { Image } from "react-bootstrap";
import "../css/home-plants-section.css";

class HomePlantsSection extends React.Component {
  render() {
    return (
      <div id="home-plants-section">
        <div id="home-plants-green-box">
          <div id="home-plants-text-container">
            <h1>After a long day's work...</h1>
            <br />
            <p>We could all use a warm hug.</p>
            <p>Whether it’s from family, friends,</p>
            <p>or a nice cup of tea. </p>
            <br />
            <p>That’s why we’re here for you. </p>
          </div>
        </div>
        <Image
          src="/images/home/plants/home_plants.svg"
          id="home-plants-image"
        />
      </div>
    );
  }
}
export default HomePlantsSection;
