import React from "react";
import { Image } from "react-bootstrap";
import { icon } from "./items.js";
import "../css/menu-item.css";

class MenuItem extends React.Component {
  render() {
    var imageUrl = "/images/menu/items/" + this.props.item.image;

    var icons = this.props.item.icons ? this.props.item.icons : [];
    var iconImages = [];
    for (var i = 0; i < icons.length; ++i) {
      var curIcon = icons[i];
      if (curIcon === icon.POPULAR) {
        iconImages.push(
          <Image
            src="/images/menu/sidebar/star.svg"
            width="18px"
            height="18px"
          />
        );
      } else if (curIcon === icon.DAIRY) {
        var style = null;
        if (i > 0) {
          style = { marginLeft: "8px" };
        }
        iconImages.push(
          <Image
            src="/images/menu/sidebar/cow.svg"
            width="18px"
            height="18px"
            style={style}
          />
        );
      }
    }

    var boxWithIcons = (
      <div style={{ display: "inline-block", margin: 0 }}>{iconImages}</div>
    );

    var chrysH3 = <h3>chrysan&shy;themum </h3>;
    var nonChrysH3 = <h3>{this.props.item.name}</h3>;

    return (
      <div id="menu-item-container">
        <Image src={imageUrl} className="menu-item-image" />
        {this.props.item.name === "Chrysanthemum" ? chrysH3 : nonChrysH3}
        {boxWithIcons}
      </div>
    );
  }
}

export default MenuItem;
