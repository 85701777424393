import React from "react";
import About from "./components/About.js";
import ContactUs from "./components/ContactUs.js";
import Footer from "./components/Footer.js";
import Header from "./components/Header.js";
import Home from "./components/Home.js";
import Menu from "./components/Menu.js";
import StoreInfo from "./components/StoreInfo.js";
import NotFound from "./components/NotFound.js";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./css/app.css";

function App() {
  return (
    <div id="app-container">
      <Header />
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/menu" component={Menu} />
          <Route exact path="/storeinfo" component={StoreInfo} />
          <Route exact path="/about" component={About} />
          <Route exact path="/contactus" component={ContactUs} />
          <Route component={NotFound} />
        </Switch>
      </BrowserRouter>
      <Footer />
    </div>
  );
}

export default App;
