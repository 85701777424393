import React from "react";
import { Image } from "react-bootstrap";
import "../css/home-featured-image-section.css";

class HomeFeaturedImageSection extends React.Component {
  render() {
    return (
      <div id="home-featured-image-section">
        <Image
          src="/images/home/landpage/landpage.png"
          id="home-featured-image"
        />
        <Image
          src="/images/home/landpage/landpage-mobile.png"
          id="home-featured-image-mobile"
        />
        <div id="home-image-text-container">
          <h1>
            Tea All Day,
            <br />
            Every Day.
          </h1>
          <a href="/menu">
            <h2>EXPLORE MENU</h2>
          </a>
        </div>
      </div>
    );
  }
}

export default HomeFeaturedImageSection;
