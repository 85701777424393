import React from "react";
import { Carousel } from "react-bootstrap";
import { FeaturedDrink } from "./HomeFeaturedDrinksSection.js";
import "../css/menu-carousel.css";

var carouselDrinkGroups = [
  { image: "taro_milkshake.png", line1: "taro", line2: "milk shake" },
  { image: "rose_yakult.png", line1: "rose", line2: "yakult" },
  {
    image: "mango_blendedyogurt.png",
    line1: "mango",
    line2: "blended yogurt",
  },
  { image: "blacksugar_milktea.png", line1: "black sugar", line2: "milk tea" },
  {
    image: "bruleeceylon_milktea.png",
    line1: "brulee ceylon",
    line2: "milk tea",
  },
  { image: "thai_milktea.png", line1: "thai", line2: "milk tea" },
];

class MenuCarousel extends React.Component {
  render() {
    var featuredDrinks = carouselDrinkGroups.map(function (drink) {
      return (
        <FeaturedDrink
          imageSrc={"/images/drinks/" + drink.image}
          line1={drink.line1}
          line2={drink.line2}
        />
      );
    });

    var carouselItems = [];
    for (
      var i = 0;
      i < featuredDrinks.length;
      i += this.props.numItemsPerPage
    ) {
      var inner = [];
      for (var j = i; j < i + this.props.numItemsPerPage; ++j) {
        inner.push(featuredDrinks[j]);
      }
      carouselItems.push(
        <Carousel.Item>
          <div className="menu-carousel-item">{inner}</div>
        </Carousel.Item>
      );
    }

    return (
      <div id="menu-carousel-container">
        <div id="most-celebrated" ref={this.props.refForChild}>
          <h1 id="menu-carousel-header">Most Celebrated</h1>
        </div>
        <Carousel id="menu-carousel">{carouselItems}</Carousel>
      </div>
    );
  }
}

export default MenuCarousel;
