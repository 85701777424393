import React from "react";
import "../css/menu-sidebar-mobile.css";
import { menu } from "./items.js";

export default class MenuSidebarMobile extends React.Component {
  render() {
    var menuWithCelebrated = [...menu];
    menuWithCelebrated.unshift({ title: "Most Celebrated" });

    var activeSection = this.props.activeSection;
    var menuSections = menuWithCelebrated.map(function (section) {
      var idName = section.title.toLowerCase().replace(" ", "-");
      var showGreenLine = activeSection.id === idName;

      return (
        <div className="menu-sidebar-mobile-section-container">
          <a href={"#" + idName}>
            <h3>{section.title.toUpperCase()}</h3>
          </a>
          <div
            className="menu-sidebar-mobile-green-line"
            style={{
              visibility: showGreenLine ? "visible" : "hidden",
            }}
          ></div>
        </div>
      );
    });

    return (
      <div id="menu-sidebar-mobile-container">
        <h2>MENU</h2>
        <div id="menu-sidebar-mobile-sections">{menuSections}</div>
      </div>
    );
  }
}
