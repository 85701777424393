import React from "react";
import { Image } from "react-bootstrap";
import "../css/storeinfo.css";
import StoreInfoTextContainer from "./StoreInfoTextContainer";

class StoreInfo extends React.Component {
  render() {
    return (
      <div id="storeinfo-container">
        <Image src="images/storeinfo/storeinfo.png" id="storeinfo-image" />
        <div id="info-location-container">
          <StoreInfoTextContainer />
          {/* <iframe
            title="Tea Addicts"
            id="storeinfo-map"
            frameborder="0"
            scrolling="no"
            marginheight="0"
            marginwidth="0"
            src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=+(Tea%20Addicts)&amp;t=&amp;z=17&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
          /> */}
        </div>
      </div>
    );
  }
}

export default StoreInfo;
