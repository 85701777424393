import React from "react";
import "../css/about-text-container.css";

class AboutTextContainer extends React.Component {
  render() {
    return (
      <div id="about-text-blue-wrapper">
        <div class="about-text-container">
          <h1>Hello there!</h1>
          <br></br>
          <p id="about-paragraph">
            My name is Jace. I lead the family here at Tea Addicts, a place
            where we take time to pause from a fast-paced and stressful world.
            <br />
            <br />
            While a good majority of shops in the bobasphere aim to please the
            eyes with bright, color-filled photos, we aim to please the heart
            and soul (also can’t forget tastebuds) with authentic flavors, high
            quality ingredients, and the right milk to tea ratio.
            <br />
            <br />
            We hope to be the drink to help start a new day or to end a long
            one. We look forward to seeing you soon!
          </p>
          <h1 id="about-signature">
            Jace & the <br /> Tea Addicts
          </h1>
        </div>
      </div>
    );
  }
}

export default AboutTextContainer;
