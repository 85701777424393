import React from "react";
import { Image } from "react-bootstrap";
import "../css/footer.css";

class FooterLine extends React.Component {
  render() {
    return (
      <div className="footer-horizontal">
        <Image
          src={this.props.imageSrc}
          width="13px"
          height="13px"
          className="footer-line-image"
        />
        <p>{this.props.line}</p>
      </div>
    );
  }
}

class FooterInfoContainer extends React.Component {
  render() {
    return (
      <div className="footer-column footer-info-container">
        <div className="footer-column" style={{ marginBottom: "8%" }}>
          <p>Roosevelt</p>
          <FooterLine
            imageSrc="/images/footer/map_icon.svg"
            line="1410 NE 65th St"
          />
          <p>Seattle, Washington 98115</p>
        </div>
        <div className="footer-column" style={{ marginBottom: "8%" }}>
          <p>Capitol Hill</p>
          <FooterLine
            imageSrc="/images/footer/map_icon.svg"
            line="1527 Harvard Avenue"
          />
          <p>Seattle, Washington 98122</p>
        </div>
        <div className="footer-column">
          <FooterLine
            imageSrc="/images/footer/phone_icon.svg"
            line="(206) 466-6004"
          />
          <FooterLine
            imageSrc="/images/footer/email_icon.svg"
            line="jacewang1994@gmail.com"
          />
        </div>
      </div>
    );
  }
}

class FooterSocialMediaContainer extends React.Component {
  render() {
    return (
      <div id="footer-social-media-container">
        <a href="https://www.facebook.com/TeaAddictsBubbleTea/">
          <Image src="/images/footer/facebook_icon.svg" />
        </a>
        <a href="https://www.instagram.com/tea_addicts_seattle/?hl=en">
          <Image src="/images/footer/insta_icon.svg" />
        </a>
      </div>
    );
  }
}

class Footer extends React.Component {
  render() {
    return (
      <div id="footer-custom">
        <div id="footer-content">
          <div id="footer-tea-addicts-logo-container">
            <Image src="/images/footer/tea.svg" />
            <Image src="/images/footer/addicts.svg" />
          </div>
          <FooterInfoContainer />
          <FooterSocialMediaContainer />
        </div>
      </div>
    );
  }
}

export default Footer;
