export const icon = {
  POPULAR: 0,
  DAIRY: 1,
};

let milkTeas = [
  {
    name: "Black Sugar",
    image: "teaaddicts_menu2_blacksugar-49.svg",
    icons: [icon.POPULAR],
  },
  {
    name: "Hokkaido",
    image: "teaaddicts_menu2_hokkaido-50.svg",
    icons: [icon.POPULAR],
  },
  {
    name: "Okinawa",
    image: "teaaddicts_menu2_okinawa-51.svg",
    icons: [icon.POPULAR],
  },
  { name: "Classic", image: "teaaddicts_menu2_classic-52.svg" },
  {
    name: "Brulee Ceylon",
    image: "teaaddicts_menu2_bruleeceylon-53.svg",
    icons: [icon.POPULAR, icon.DAIRY],
  },
  { name: "Thai", image: "teaaddicts_menu2_thai-54.svg", icons: [icon.DAIRY] },
  { name: "Oolong", image: "teaaddicts_menu2_oolong-55.svg" },
  { name: "Taro", image: "teaaddicts_menu2_taro-56.svg" },
  { name: "Matcha", image: "teaaddicts_menu2_matcha-57.svg" },
  { name: "Jasmine", image: "teaaddicts_menu2_jasmine-58.svg" },
  { name: "Rose", image: "teaaddicts_menu2_rose-59.svg" },
  {
    name: "Royal",
    image: "teaaddicts_menu2_royal-60.svg",
    icons: [icon.DAIRY],
  },
  { name: "Earl Grey", image: "teaaddicts_menu2_earlgrey-61.svg" },
];

let herbalTeas = [
  {
    name: "Chrysanthemum",
    image: "teaaddicts_menu2_chrysanthemum-62.svg",
    icons: [icon.POPULAR],
  },
  { name: "Longan", image: "teaaddicts_menu2_longan-63.svg" },
  {
    name: "Lavender",
    image: "teaaddicts_menu2_lavender-64.svg",
    icons: [icon.POPULAR],
  },
  { name: "Ginger", image: "teaaddicts_menu2_ginger-65.svg" },
  { name: "Hibiscus", image: "teaaddicts_menu2_hibiscus-66.svg" },
];

let fruitTeas = [
  { name: "Mango", image: "teaaddicts_menu2_mango-67.svg" },
  { name: "Peach", image: "teaaddicts_menu2_peach-68.svg" },
  {
    name: "Grapefruit",
    image: "teaaddicts_menu2_grapefruit-69.svg",
    icons: [icon.POPULAR],
  },
  { name: "Strawberry", image: "teaaddicts_menu2_strawberry-70.svg" },
  {
    name: "Yakult",
    image: "teaaddicts_menu2_yakult-71.svg",
    icons: [icon.DAIRY],
  },
  { name: "Passion Fruit", image: "teaaddicts_menu2_passionfruit-72.svg" },
];

let toppings = [
  { name: "Boba", image: "teaaddicts_menu2_boba-76.svg" },
  {
    name: "Cheesefoam",
    image: "teaaddicts_menu2_cheesefoam-77.svg",
    icons: [icon.DAIRY],
  },
  {
    name: "Pudding",
    image: "teaaddicts_menu2_pudding-78.svg",
    icons: [icon.DAIRY],
  },
  { name: "Grass Jelly", image: "teaaddicts_menu2_grassjelly-79.svg" },
  { name: "Lychee Jelly", image: "teaaddicts_menu2_lycheejelly-80.svg" },
  {
    name: "Crystal Boba",
    image: "teaaddicts_menu2_crystalboba-81.svg",
    icons: [icon.DAIRY],
  },
];

let smoothies = [
  {
    name: "Mango",
    image: "teaaddicts_menu2_mango-67.svg",
    icons: [icon.DAIRY],
  },
  {
    name: "Taro",
    image: "teaaddicts_menu2_taro-56.svg",
    icons: [icon.POPULAR, icon.DAIRY],
  },
  {
    name: "Strawberry",
    image: "teaaddicts_menu2_strawberry-70.svg",
    icons: [icon.DAIRY],
  },
  {
    name: "Matcha",
    image: "teaaddicts_menu2_matcha-57.svg",
    icons: [icon.DAIRY],
  },
];

let blendedYogurts = [
  {
    name: "Mango",
    image: "teaaddicts_menu2_mango-67.svg",
    icons: [icon.POPULAR, icon.DAIRY],
  },
  {
    name: "Peach",
    image: "teaaddicts_menu2_peach-68.svg",
    icons: [icon.DAIRY],
  },
  {
    name: "Strawberry",
    image: "teaaddicts_menu2_strawberry-70.svg",
    icons: [icon.DAIRY],
  },
  {
    name: "Grapefruit",
    image: "teaaddicts_menu2_grapefruit-69.svg",
    icons: [icon.DAIRY],
  },
];

let decafRefreshers = [
  {
    name: "Rose Yakult",
    image: "teaaddicts_menu2_roseyakult-73.svg",
    icons: [icon.POPULAR, icon.DAIRY],
  },
  { name: "Peach", image: "teaaddicts_menu2_peach-68.svg" },
  { name: "Kumquat", image: "teaaddicts_menu2_kumquat-74.svg" },
  { name: "Mixed Fruit", image: "teaaddicts_menu2_mixedfruit-75.svg" },
];

let fingerFoods = [
  {
    name: "Popcorn Chicken",
    image: "teaaddicts_menu2_popcornchicken-82.svg",
    icons: [icon.POPULAR],
  },
  { name: "Fried Tofu", image: "teaaddicts_menu2_friedtofu-83.svg" },
  { name: "Fries", image: "teaaddicts_menu2_fries-84.svg" },
  {
    name: "Sweet Potato Fries",
    image: "teaaddicts_menu2_sweetpotatofries-85.svg",
  },
];

export let menu = [
  {
    title: "Milk Tea",
    subtitle:
      "Made with high quality non-dairy creamer and freshly brewed premium loose-leaf teas",
    items: milkTeas,
  },
  {
    title: "Fruit Tea",
    subtitle: "Jasmine tea based fresh fruit teas",
    items: fruitTeas,
  },
  {
    title: "Toppings",
    subtitle: "Made fresh daily and can be added to any drinks",
    items: toppings,
  },
  {
    title: "Smoothies",
    subtitle:
      "Made with fresh milk blended with ice, milk can be substituted to your preference.",
    items: smoothies,
  },
  {
    title: "Blended Yogurt",
    subtitle:
      "Greek yogurt blended with ice and sweetened by condense milk and house made fruit sauce made with fresh fruits",
    items: blendedYogurts,
  },
  {
    title: "Decaf Refreshers",
    subtitle: "Juices with no caffeine",
    items: decafRefreshers,
  },
  {
    title: "Finger Foods",
    subtitle: "Taiwanese street style fried food",
    items: fingerFoods,
  },
];
